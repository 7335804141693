import React, { ReactElement } from "react";

import ConnectIcon from "../icons/connectIcon";
import MatchYouWithTheBestCompaniesIcon from "../icons/matchYouWithTheBestCompaniesIcon";
import CreditRepairEasierIcon from "../icons/creditRepairEasierIcon";
import FreeOfChargeIcon from "../icons/freeOfChargeIcon";
import BestFinancialSolutionsIcon from "../icons/bestFinancialSolutionsIcon";
import CreditScoreIcon from "../icons/creditScoreIcon";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "We can help you no matter how bad your credit score is!",
        icon: <CreditScoreIcon />,
    },
    {
        title: "We Connect You to Companies for Free",
        icon: <ConnectIcon />,
    },
    {
        title: "We Match You With the Best Companies!",
        icon: <MatchYouWithTheBestCompaniesIcon />,
    },
    {
        title: "We Make Credit Repair Easier Than Ever!",
        icon: <CreditRepairEasierIcon />,
    },
    {
        title: "The Consultation Is Free of Charge!",
        icon: <FreeOfChargeIcon />,
    },
    {
        title: "Get the Best Help for Your Credit Score!",
        icon: <BestFinancialSolutionsIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Why CreditTent.com"
            classes={{
                mainSectionClasses:
                    "max-w-7xl mx-auto block px-4vw lg:px-4 pt-20 pb-16",
                mainTitleClasses:
                    "text-center text-2xl lg:text-4xl mb-14 font-bold",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: " children:mx-auto",
                titleClasses: `font-medium text-lg lg:text-xl mt-6 font-light mx-auto  max-w-[220px] w-full`,
            }}
            colors={{
                bgColor: "#f2f2f2",
                titleColor: "#535353",
            }}
        />
    );
}
