import React, { ReactElement } from "react";

export default function ConnectIcon(): ReactElement {
    return (
        <svg
            width="81"
            height="80"
            viewBox="0 0 81 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_23_588_1)">
                <path
                    d="M32.1487 37.6134L29.2553 40.482C28.7113 41.0261 28.1672 41.5948 27.5984 42.1142C27.5163 42.1488 27.4279 42.1667 27.3387 42.1667C27.2495 42.1667 27.1613 42.1488 27.0791 42.1142C26.4315 41.855 25.7203 41.7989 25.04 41.9535C24.3598 42.1081 23.7428 42.466 23.2708 42.9797L7.91376 58.3367C7.53047 58.6522 7.22179 59.0487 7.00987 59.4976C6.79795 59.9466 6.688 60.4369 6.688 60.9333C6.688 61.4298 6.79795 61.92 7.00987 62.369C7.22179 62.8179 7.53047 63.2144 7.91376 63.5299C10.7329 66.382 13.552 69.2012 16.3712 71.9874C16.689 72.3755 17.089 72.6882 17.5423 72.9029C17.9956 73.1176 18.4909 73.229 18.9925 73.229C19.4941 73.229 19.9895 73.1176 20.4428 72.9029C20.8961 72.6882 21.2961 72.3755 21.6139 71.9874L36.872 56.754C37.5007 56.2278 37.9156 55.49 38.0385 54.6794C38.1615 53.8688 37.984 53.0412 37.5397 52.3522L42.2878 47.6536C42.5598 48.0245 42.8813 48.3954 43.1533 48.8158C44.424 50.7347 44.9859 53.0366 44.7422 55.3251C44.4984 57.6136 43.4643 59.7456 41.8179 61.3537C36.6 66.6211 31.3573 71.839 26.1146 77.0817C25.1926 78.0314 24.0894 78.7864 22.8703 79.302C21.6511 79.8176 20.341 80.0833 19.0173 80.0833C17.6936 80.0833 16.3834 79.8176 15.1643 79.302C13.9452 78.7864 12.8419 78.0314 11.9199 77.0817C8.95238 74.1141 5.9601 71.1466 3.01728 68.1543C2.0789 67.2335 1.33347 66.1348 0.824622 64.9226C0.315771 63.7103 0.0537109 62.4088 0.0537109 61.0941C0.0537109 59.7793 0.315771 58.4778 0.824622 57.2655C1.33347 56.0533 2.0789 54.9546 3.01728 54.0338C8.25994 48.7417 13.5274 43.4825 18.8195 38.2563C20.4931 36.5676 22.719 35.5382 25.0896 35.3566C27.4602 35.1749 29.8169 35.8532 31.7283 37.2672L32.1487 37.6134Z"
                    fill="#4E4E4E"
                />
                <path
                    d="M47.7036 42.1887L52.427 37.4653C52.8313 37.751 53.2969 37.9378 53.7865 38.0108C54.2761 38.0838 54.776 38.041 55.2461 37.8857C55.7735 37.6997 56.2605 37.4142 56.6804 37.0449C61.9231 31.8352 67.1657 26.5926 72.4084 21.3169C73.026 20.696 73.3727 19.8558 73.3727 18.98C73.3727 18.1042 73.026 17.264 72.4084 16.6431C69.4408 13.6261 66.4239 10.6338 63.4069 7.64152C63.0985 7.32823 62.7309 7.07943 62.3254 6.9096C61.92 6.73977 61.4848 6.65231 61.0452 6.65231C60.6056 6.65231 60.1704 6.73977 59.7649 6.9096C59.3595 7.07943 58.9919 7.32823 58.6835 7.64152L43.1286 23.3695C42.6464 23.8256 42.3147 24.4179 42.1777 25.0674C42.0408 25.7169 42.1051 26.3927 42.362 27.0047C42.3997 27.0861 42.4192 27.1747 42.4192 27.2644C42.4192 27.3541 42.3997 27.4427 42.362 27.5241L37.7871 32.099L37.4161 31.6292C36.0021 29.7178 35.3238 27.361 35.5055 24.9904C35.6871 22.6198 36.7166 20.394 38.4053 18.7203C41.3481 15.7281 44.3156 12.7853 47.2832 9.81772L54.1086 2.99237C55.0309 2.03884 56.1357 1.28059 57.3571 0.762739C58.5785 0.244892 59.8916 -0.0219727 61.2183 -0.0219727C62.545 -0.0219727 63.8581 0.244892 65.0795 0.762739C66.3009 1.28059 67.4057 2.03884 68.328 2.99237C71.345 5.95992 74.3373 8.95219 77.3049 11.9692C78.2283 12.8883 78.961 13.9808 79.461 15.1839C79.961 16.3871 80.2184 17.6771 80.2184 18.98C80.2184 20.2829 79.961 21.5729 79.461 22.7761C78.961 23.9792 78.2283 25.0717 77.3049 25.9908C72.0457 31.3159 66.7618 36.5998 61.4532 41.8424C59.7126 43.594 57.3798 44.6312 54.9134 44.7502C52.4469 44.8691 50.0251 44.0613 48.124 42.4854L47.7036 42.1887Z"
                    fill="#4E4E4E"
                />
                <path
                    d="M21.1685 55.4187C21.11 54.8131 21.1861 54.2019 21.3913 53.6291C21.5965 53.0562 21.9258 52.5358 22.3556 52.105L43.0789 31.3816L52.2041 22.2564C52.6752 21.7409 53.2858 21.3731 53.9617 21.1975C54.6377 21.0219 55.35 21.0462 56.0124 21.2673C56.6312 21.4484 57.1886 21.7953 57.6245 22.2704C58.0604 22.7455 58.3581 23.3307 58.4854 23.9628C58.6498 24.5831 58.6448 25.2361 58.4708 25.8538C58.2968 26.4715 57.9602 27.0311 57.4962 27.4744C54.6111 30.3925 51.7095 33.2941 48.7914 36.1792C41.7683 43.2023 34.7203 50.2255 27.7218 57.2735C27.117 57.9601 26.2892 58.4111 25.3843 58.5468C24.4794 58.6825 23.5556 58.4943 22.7759 58.0153C22.3286 57.7328 21.9514 57.3522 21.6729 56.9024C21.3944 56.4525 21.2219 55.9451 21.1685 55.4187V55.4187Z"
                    fill="#4E4E4E"
                />
            </g>
            <defs>
                <clipPath id="clip0_23_588_1">
                    <rect width="80.0495" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
