import React, { ReactElement } from "react";

export default function MatchYouWithTheBestCompaniesIcon(): ReactElement {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_23_572_0)">
                <path
                    d="M3.71677 15.6079H34.7771V3.70505C34.7771 2.67576 35.1957 1.74217 35.8702 1.06835C36.5283 0.409502 37.4294 0 38.4111 0H76.2774C77.2591 0 78.1595 0.408851 78.8177 1.06835C79.4915 1.74282 79.9102 2.67576 79.9102 3.70505V60.6864C79.9102 61.7163 79.4915 62.6499 78.8177 63.3237C78.1589 63.9826 77.2585 64.3921 76.2774 64.3921H45.2164V76.295C45.2164 77.3249 44.7965 78.2585 44.1227 78.9323C43.4638 79.5912 42.5635 80.0007 41.5823 80.0007H3.71677C2.73435 80.0007 1.83462 79.5912 1.17577 78.9323C0.501298 78.2585 0.0826816 77.3249 0.0826816 76.295V48.4299C0.0286456 48.2301 0 48.0204 0 47.8043C0 47.5869 0.0286456 47.3772 0.0826816 47.1773V19.3136C0.0826816 18.2837 0.501298 17.3501 1.17577 16.6763C1.83462 16.0174 2.73435 15.6079 3.71677 15.6079ZM61.271 18.9692C62.1792 18.0135 63.6909 17.9764 64.646 18.8853C65.6023 19.7941 65.6388 21.3058 64.7299 22.2609L57.6258 29.6931H75.2891V4.76494H39.3988V15.6079H41.5823C42.5641 15.6079 43.4645 16.0168 44.1227 16.6763C44.7965 17.3501 45.2164 18.2837 45.2164 19.3136V59.6271H75.2884V34.4808H57.6128L64.7299 41.9521C65.6388 42.9085 65.6023 44.4189 64.646 45.3284C63.6909 46.2373 62.1792 46.1995 61.271 45.2444L50.3056 33.7335C49.4332 32.8174 49.4241 31.3676 50.3056 30.4412L61.271 18.9692ZM14.9367 37.9769C14.0279 37.0212 14.0656 35.5101 15.0207 34.6013C15.9764 33.6924 17.4875 33.7302 18.3963 34.6853L29.3617 46.1572C30.2432 47.0843 30.2341 48.5335 29.3611 49.4495L18.3963 60.9611C17.4875 61.9162 15.9764 61.9539 15.0207 61.0451C14.0656 60.1369 14.0279 58.6252 14.9367 57.6701L22.0539 50.1969H4.70504V75.2351H40.5947V20.3722H4.70504V45.4098H22.0408L14.9367 37.9769Z"
                    fill="#4E4E4E"
                />
            </g>
            <defs>
                <clipPath id="clip0_23_572_0">
                    <rect width="79.9095" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
